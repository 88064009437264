import React, { useState, useEffect } from "react";
import { Container, FilePdfOutlined, SpinnerPanel } from "../../.."
import OfferCard, { OfferCardSkeleton } from "../offer-card"
import { exportPDF } from "../../pdf-util"
import css from "./style.module.scss"

import { exportAsImage, render } from "../../../../utils"

import { graphql, useStaticQuery } from "gatsby";
import { v4 as uuidv4 } from 'uuid';

let agent;

const OfferCardListSkeleton = () => <Container className={css.offerCardListContainer}>
    <SpinnerPanel />

    <OfferCardSkeleton />
    <OfferCardSkeleton />

    <OfferCardSkeleton />

    <OfferCardSkeleton />

    <OfferCardSkeleton />

</Container>

export default (props) => {

    if (props.loading) {
        return <OfferCardListSkeleton />
    }

    const { data } = props;

    agent = {
        name: data.site.siteMetadata.agent.name,
        title: data.site.siteMetadata.agent.title,
        email: data.site.siteMetadata.agent.email,
        website: data.site.siteMetadata.agent.website,
        phone: data.site.siteMetadata.agent.phone,
        image: data.site.siteMetadata.agent.assets.image
    }

    const [selectedItems, updateSelectedItems] = useState(new Set(props.items.map(i => i.id)));
    const [selectVisible, setSelectVisible] = useState(false);

    const onOffercardSelected = (value) => {
        const newSelectedItems = new Set([...selectedItems]);
        value.checked ? newSelectedItems.add(value.id) : newSelectedItems.delete(value.id);
        updateSelectedItems(newSelectedItems);
    }

    useEffect(() => {
        updateSelectedItems(new Set(props.items.map(i => i.id)))
    }, props.items)

    const { filters } = props;

    const filterItems = (items) => {
        let filteredOffers = items;
        // if (filters.carriers.length > 0) {
        //     filteredOffers = filteredOffers.filter(i => filters.carriers.includes(i.issuer.name))
        // }

        // if (filters.metalLevels.length > 0) {
        //     filteredOffers = filteredOffers.filter(i => filters.metalLevels.includes(i.metal_level))
        // }

        // if (filters.priceRange ?.Premiums ?.value[0] ||
        //     filters.priceRange ?.Premiums ?.value[1]) {
        //     filteredOffers = filteredOffers.filter(i =>
        //         i.premium >= filters.priceRange ?.Premiums ?.value[0] &&
        //             i.premium <= filters.priceRange ?.Premiums ?.value[1]
        //     )
        // }

        // if (
        //     filters.priceRange ?.Deductibles ?.value[0] ||
        //         filters.priceRange ?.Deductibles ?.value[1]
        // ) {
        //     filteredOffers = filteredOffers.filter(i =>
        //         i.deductibles[0].amount >= filters.priceRange ?.Deductibles ?.value[0] &&
        //             i.deductibles[0].amount <= filters.priceRange ?.Deductibles ?.value[1]
        //     )
        // }

        return filteredOffers;
    }

    const filteredOffers = filterItems(props.items);



    return <Container className={`${css.offerCardListContainer} scrollbar`}>

        {<Container className={css.beforeResults}>

            <Container className={css.resultsContainer}>
                <Container onPress={() => { setSelectVisible(!selectVisible) }} className={`${css.resultsSelectContainer} ${selectVisible ? "" : css.selectDisabled} `}>
                    Selected ({selectedItems.size})
                </Container>

                <Container onPress={() => { exportPDF(filteredOffers.filter(f => selectedItems.has(f.id)), agent) }} className={`${css.resultsShareContainer}`}>
                    Download
                    <FilePdfOutlined />
                </Container>
            </Container>

        </Container>
        }
        {
            selectVisible ? selectedItems.size === filteredOffers.length ?

                <Container style={{ marginTop: "12px" }} onPress={() => { updateSelectedItems(new Set()) }}
                >
                    unselect all
                    </Container>
                :
                <Container style={{ marginTop: "12px" }}
                    onPress={() => { updateSelectedItems(new Set(props.items.map(i => i.id))) }}
                >
                    select all
            </Container> : <Container />
        }
        {
            filteredOffers.map((i, ind) => <OfferCard key={uuidv4()} showSelection={selectVisible} imgid={"card" + ind} exportImage={
                async () => {

                    const imageContainer = document.createElement("div");


                    imageContainer.setAttribute(
                        "style",
                        `width:600px;height:324px;display: flex;flex-direction:column;justify-content:space-between; align-items: center;padding-bottom: 12px;`
                    )


                    const card = document.getElementById("card" + ind);

                    const headerElement = document.createElement("div")
                    headerElement.setAttribute("id", "card" + ind + "header")
                    document.body.append(headerElement)

                    const header = document.createElement('div');


                    await render(<div className={`${css.headerStyle}`}>
                        <div>
                            <img className={css.imageStyle} src={data.site.siteMetadata.agent.assets.image} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "12px" }}>
                            <div className={`${css.defaultStyle} ${css.nameStyle}`}>{agent.name}</div>
                            <div className={`${css.defaultStyle} ${css.titleStyle}`}>{agent.title}</div>
                            <div className={`${css.defaultStyle} ${css.underscoreStyle}`} />
                            <div className={`${css.defaultStyle}`}>{agent.email}</div>
                            <div className={`${css.defaultStyle}`}>{agent.website}</div>
                            <div className={`${css.defaultStyle}`}>{agent.phone}</div>
                        </div>
                        <div style={{ flexGrow: 1 }} />
                        <div className={css.qrCodeStyle}>
                            <canvas className={"replace-with-qr-code"} />
                        </div>
                    </div>, header)

                    imageContainer.innerHTML = `
                        
                        ${header.innerHTML}
                        
                        
                        <div style="width:480px" ${card.innerHTML.substring(4)}
                    
                    `

                    exportAsImage("quotes", "jpg", imageContainer)
                }
            } onSelectChange={onOffercardSelected} selected={selectedItems.has(i.id)} key={i.id} {...i} />)
        }
    </Container>
}