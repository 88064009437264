import React, { useState } from "react";

import DecisionSupport from "./decision-support";

import { Avatar, Badge, Button, Container } from "../../../"
import { FacebookFilled, ScheduleOutlined } from "../../../"

import css from "./style.module.scss";




export default ({ data, inputs, mobileActiveContainer, onPicwellOn, onPicwellOff, enabled }) => {
    console.log("mobile active", mobileActiveContainer)
    return <Container className={`${css.rightBarContainer} ${mobileActiveContainer !== "user-info" ? "hidden-on-mobile" : ""}`}>
        {<Container className={`${css.contactSupportContainer}`}>
            <Container className={css.contactSupport}>
                <Container className={css.msg1} >Want to get an accurate quote?</Container>
                <Container className={css.msg2}>Let {data?.site?.siteMetadata?.agent?.name} help you</Container>
                <Container className={css.contactCard}>
                    <Container className={css.avatarContainer}>
                        <Container className={css.isOnline}>
                            <Badge size="small" color="#00e675" />
                            <Container className={css.isOnlineText}>
                                online now
                            </Container>
                        </Container>
                        <Avatar link="" round src={data?.site?.siteMetadata?.agent?.imageSrc} />
                    </Container>
                    <Container className={css.messageButtonsContainer}>
                        <a target="_blank" href={"https://m.me/ApolloInsuranceAdvisors"}>
                            <Button shape="round" className={css.sendMessageFacebookButton} >Send Message <Container className={css.sendMessageFacebookIcon}> <FacebookFilled /></Container> </Button>
                        </a>
                        <a target="_blank" href={"https://meet.google.com/linkredirect?authuser=0&dest=https%3A%2F%2Fcalendly.com%2Fdillonr%2F15min"}>
                            <Button shape="round" className={css.scheduleMeetingButton} > Schedule Call <Container className={css.scheduleMeetingIcon}> <ScheduleOutlined /> </Container> </Button>
                        </a>
                        {/* <a target="_blank" href={ "https://wa.me/+55(31)984616723/?text=" + (`Hello, I would like to get a health insurance quote with you, for a person with ${props.age} years old and zip code ${props.zipCode}.`) }><Button shape="round" className={css.sendMessageWhatsappButton} >Send Message <Container className={css.sendMessageWhatsappIcon}><WhatsAppOutlined /></Container></Button></a> */}
                    </Container>
                </Container>
            </Container>
        </Container>}
        {<DecisionSupport enabled={enabled} onPicwellOn={onPicwellOn} onPicwellOff={onPicwellOff} onSubmit={onPicwellOn} />}
        {
            inputs
        }

    </Container>
}