import React from "react";
import { Accordeon, Container, Badge } from "../../.."
import css from "./style.module.scss";
import FilterGroup from "./filter-group";
import { ApolloCollors } from "../../../rules/design/apollo-colors";
import { v4 as uuid } from 'uuid';



const buildFilters = (items) => {

    return <>
        {
            items.map(item => {
                if (item.type === "GROUP") {
                    return <FilterGroup key={uuid()} {...item} />
                }
            })
        }
    </>
}

export default (props) => {

    const selectedCarriersChanged = (value) => { props.selectedCarriersChanged && props.selectedCarriersChanged(value) };
    const selectedMetalLevelsChanged = (value) => { props.selectedMetalLevelsChanged && props.selectedMetalLevelsChanged(value) };
    const selectedPriceRangeChanged = (value) => { props.selectedPriceRangeChanged && props.selectedPriceRangeChanged(value) };


    return <Container className={css.searchFiltersContainer}>
        {props.data.map(facetItem => {
            if (facetItem.type === "GROUP") {
                return <Accordeon key={uuid()} title={facetItem.label}>
                    {
                        buildFilters(facetItem.values)
                    }
                </Accordeon>
            }
        })}
    </Container>
}