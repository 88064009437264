import React, { useState, useEffect } from "react"
import axios from "axios";
import { AddDependentInput, Button, Container, NumberSlider, WomanOutlined, ManOutlined, SelectOption, Select, TextInput, UserAddOutlined, UsergroupAddOutlined, PlusOutlined, CloseCircleFilled, EnvironmentOutlined, HomeOutlined, UserOutlined } from "../../../.."
import css from "./style.module.scss";

import { Radio, Popover } from "antd"
import generateCalendar from "antd/lib/calendar/generateCalendar";
import SubsidiesCheck from "../subsidies-check"

import { v4 as uuid } from "uuid";
import subsidiesCheck from "../subsidies-check";
import { EditOutlined, HomeTwoTone } from "../../../../icon";

import { Button as AntdButton } from "antd"
import { Hmac } from "crypto";
const handleInputChange = event => {
    const target = event.target
    const value = target.value

    console.log("value", value)
    return value;
}

export default (props) => {

    const [zip_code, setZipCode] = useState(props.zip_code)
    const [county, setCounty] = useState(props.county)
    const [avaiableCounties, setAvaiableCounties] = useState(props.avaiableCounties)
    const [income, setIncome] = useState(props.income);
    const [age, setAge] = useState(props.age)
    const [gender, setGender] = useState(props.gender);

    const [householdMembers, setHoulseholdMembers] = useState([])

    const [spouse, setSpouse] = useState();

    const [tobacco, setTobacco] = useState(false);

    const [spousePopupState, setSpousePopupState] = useState({
        hovered: false,
        clicked: false,
    });


    const [dependentsPopupState, setDependentsPopupVisible] = useState({
        current: null,
        hovered: false,
        clicked: false,
    });


    function addDependant(dependent) {
        setDependentsPopupVisible({
            current: null,
            hovered: false,
            clicked: false,
        })
        setHoulseholdMembers([...householdMembers.map((h, index) => ({ ...h, open: false, index: Number(index) })), {
            index: householdMembers.length,
            ...dependent
        }]);
    }

    function updateDependant(dependent, index) {
        setDependentsPopupVisible({
            current: null,
            hovered: false,
            clicked: false,
        })


        if (_.isEmpty(dependent)) {
            setHoulseholdMembers(householdMembers.filter((d, i) => { return i !== index }));
        } else {
            console.log("dependent", householdMembers.map((h, i) => i === index ? { ...h } : { ...h, ...dependent }))
            setHoulseholdMembers(householdMembers.map((h, i) => {

                console.log("i", i)
                console.log("index", index)
                return i === index ? { ...h, ...dependent } : { ...h }
            }
            ));
        }

    }

    function addSpouse(spouse) {
        setSpousePopupState({
            clicked: false,
            hovered: false,
        })
        setSpouse(spouse)
    }

    const zipCodeChage = async (event) => {


        setZipCode(handleInputChange(event))
        if (!(event.target && event.target.value && event.target.value.length === 5)) {
            setAvaiableCounties([])
            setCounty(null);
            return;
        }

        const response = await axios({
            method: "get",
            url: `https://api.qa.apolloquotes.com/check-zip-code/${event.target.value}`,
        })


        if (response.status == 200 && response.data.status != "400" && response.data.counties.length > 0) {

            const { counties } = response.data;
            setCounty(counties[0])
            setAvaiableCounties(counties)
        } else {
            setAvaiableCounties([])
            setCounty(null);
        }

    }

    const handleHoverChange = visible => {
        console.log("hover")
        setSpousePopupState({
            hovered: visible,
            clicked: spousePopupState.clicked,
        });
    };

    const handleClickChange = visible => {
        console.log("clicked", visible)
        setSpousePopupState({
            clicked: !spousePopupState.clicked,
            hovered: false,
        });
    };


    const handleDependentHoverChange = (item, visible) => {
        setDependentsPopupVisible({
            current: item,
            hovered: visible,
            clicked: dependentsPopupState.clicked,
        })
    }


    const handleDependentClickChange = (item) => {
        setDependentsPopupVisible({
            current: item,
            hovered: false,
            clicked: !dependentsPopupState.clicked,
        })
    }


    useEffect(() => {
    }, [
        householdMembers
    ])


    const Temp = ({ age, gender, open, tobacco, title: key, index }) => <Container>


        {
            <Container>
                <Container className={css.inputContainer}>
                    <Container className={css.inputLabel}>
                        Gender
                                </Container>
                    <Container className="flex1">
                        <Select value={gender} allowClear showArrow={!gender} onChange={setGender} dropdownStyle={{ background: "#fff" }}>
                            <SelectOption className={`${css.bgWhite}`} value="Male">
                                <Container className={`${css.genderInputMaleContainer} ${gender === "Male" ? css.maleActive : ""}`}>
                                    Male
                            <ManOutlined />
                                </Container>
                            </SelectOption>
                            <SelectOption className={css.bgWhite} value="Female">
                                <Container className={`${css.genderInputFemaleContainer} ${gender === "Female" ? css.femaleActive : ""}`}>
                                    Female
                            <WomanOutlined />
                                </Container>
                            </SelectOption>
                        </Select>
                    </Container>
                </Container>


                <Container className={css.inputContainer}>
                    <Container className={css.inputLabel}>

                        Age
                    </Container>
                    <Container className={css.inputText}><TextInput value={age} onChange={(event) => {

                        setAge(handleInputChange(event))

                    }}
                        style={{ height: "30px" }} allowClear /></Container>
                </Container>



                {
                    age >= 18 && <Container className={css.inputContainer}>
                        <Container className={css.inputLabel}>
                            Tobacco
                    </Container>
                        <Radio.Group value={tobacco} onChange={(e) => setTobacco(e.target.value)} className={css.yesNoContainer}>
                            <Radio.Button value={true} children="yes" />
                            <Radio.Button value={false} children="no" />
                        </Radio.Group>
                    </Container>
                }


            </Container>
        }
    </Container>

    return <Container className={css.userInputs}>
        <Container className={css.quoteType}>
            Location
            <EnvironmentOutlined />
        </Container>
        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>
                Zip Code
            </Container>
            <Container className={css.inputText}><TextInput onChange={zipCodeChage} value={zip_code} style={{ height: "30px" }} allowClear /></Container>
        </Container>

        {
            avaiableCounties && avaiableCounties.length > 0 && <Container className={css.inputContainer}>
                <Container className={css.inputLabel}>
                    County
            </Container>
                <Select size="large" options={
                    avaiableCounties.map(a => {
                        return {
                            label: a.name,
                            value: a.name,
                        }
                    })}

                    value={county ? county.name : null}

                    defaultValue={avaiableCounties[0].name}

                    onChange={(v) => {
                        setCounty(avaiableCounties.filter(f => f.name == v)[0])
                    }} />

            </Container>

        }

        <Container className={css.profileContainer}>
            <Container className={css.quoteType}>
                Profile
                <UserOutlined />
            </Container >

            <Temp age={age} tobacco={tobacco} gender={gender} title="Profile" />

            <Container className={css.quoteType}>
                Houlsehold
                <HomeOutlined />
            </Container >

            <Container>
                <Container className={css.householdMembers}>
                    {
                        householdMembers.map((hm, index) => {
                            return <Popover key={`pop-${index}`}
                                visible={dependentsPopupState.current === index && (dependentsPopupState.hovered || dependentsPopupState.clicked)}
                                onVisibleChange={(visible) => {
                                    handleDependentHoverChange(index, visible)
                                }}
                                trigger="hover" placement="bottom" title={`Dependent ${Number(index + 1)}`} content={<AddDependentInput {...householdMembers[Number(index)]} onSubmit={(d) => { updateDependant(d, index) }} />}>
                                <Container onClick={() => {
                                    handleDependentClickChange(index)
                                }} className={`${css.householdMember} ${spousePopupState.clicked ? css.active : ""}`}>


                                    <Container className={css.householdMemberIcon}>
                                        {householdMembers[Number(index)].gender == "Female" ? <WomanOutlined className={css.femaleActive} /> : <ManOutlined className={css.maleActive} />}
                                    </Container>
                                    <Container>
                                        {`DEPENDENT`}
                                    </Container>
                                    <Container className={css.dependentAgeContainer}>
                                        {householdMembers[Number(index)].age}
                                    </Container>
                                </Container>
                            </Popover>
                        })
                    }

                    <Popover
                        visible={dependentsPopupState.current == null && (dependentsPopupState.hovered || dependentsPopupState.clicked)}

                        onVisibleChange={(visible) => {
                            handleDependentHoverChange(null, visible)
                        }}
                        trigger="hover" placement="bottom" title="Dependent" content={<AddDependentInput onSubmit={addDependant} />}>
                        <Container onClick={() => {
                            handleDependentClickChange(null)
                        }} className={`${css.householdMember} ${spousePopupState.clicked ? css.active : ""}`}>


                            <Container className={css.householdMemberIcon}>
                                <UsergroupAddOutlined />
                            </Container>
                            <Container>ADD DEPENDENT</Container>
                        </Container>
                    </Popover>

                    <Popover
                        visible={spousePopupState.hovered || spousePopupState.clicked}
                        onVisibleChange={handleHoverChange}
                        trigger="hover" placement="bottom" title="Spouse" content={<AddDependentInput {...spouse} onSubmit={addSpouse} />}>
                        <Container onClick={handleClickChange} className={`${css.householdMember} ${spousePopupState.clicked ? css.active : ""}`}>
                            {
                                spouse ? <>

                                    <Container className={css.householdMemberIcon}>
                                        {spouse.gender == "Female" ? <WomanOutlined className={css.femaleActive} /> : <ManOutlined className={css.maleActive} />}
                                    </Container>
                                    <Container>
                                        SPOUSE
                            </Container>
                                    <Container className={css.dependentAgeContainer}>
                                        {spouse.age}
                                    </Container>
                                </> :

                                    <>
                                        <Container className={css.householdMemberIcon}>
                                            <UserAddOutlined />
                                        </Container>
                                        <Container>ADD SPOUSE</Container>
                                    </>

                            }
                        </Container>
                    </Popover>

                </Container>

            </Container>

        </Container>
        <br />

        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>
                Income
            </Container>
            <Container className={css.inputText}><TextInput onChange={(event) => setIncome(handleInputChange(event))} value={income} style={{ height: "30px" }} allowClear /></Container>
        </Container>

        <Container style={{ margin: "12px auto", display: "flex", width: "78px" }}>


            {county && age ?
                <Button shape="round" onClick={() => {
                    props.onSubmit && props.onSubmit({
                        age: age,
                        gender: gender,
                        income,
                        zip_code,
                        fips_code: county.fips,
                        state: county.state,
                    })
                }} type="primary">
                    Submit
                </Button> :
                <Button disabled style={{ opacity: 0.7, background: "#1B75BC", color: "#fff" }} shape="round" onClick={() => { }} type="primary">
                    Submit
                </Button>}
        </Container>
    </Container>
}