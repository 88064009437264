import React from "react";
import { Badge, Checkbox, Container, ShareAltOutlined } from "../../..";
import { Skeleton } from "antd";

import css from "./style.module.scss";

export const metalLevelColors = {
    Bronze: "#6A3805",
    Catastrophic: "#575988",
    Gold: "#AF9500",
    Platinum: "#3B3B41",
    Silver: "#B4B4B4"
}


export const OfferCardSkeleton = () => {
    return <Container className={css.offerCardContainer}>
        <Container style={{ borderColor: "#EEE", padding: "24px" }} className={css.infoContainer} >
            <Skeleton title={false} paragraph={{ rows: 4, width: "100%" }} active />
        </Container>
    </Container>
}

export default ({ innerStyle, style, score, carrier, imgid, exportImage, pdf, onSelectChange, deductible, id, plan_name, premium, selected, info, showSelection }) => {

    return <Container style={style} id={imgid} className={`${css.offerCardContainer} ${pdf ? css.pdf : css.screen}`}>
        <Container style={innerStyle} className={`${css.infoContainer} ${pdf ? css.infoContainerScreenOnly : ""} ${score ? score > 50 ? css.green : score > 25 ? css.yellow : css.red : ""}`}>
            {showSelection && <Container className={css.checkboxContainer}>
                <Checkbox onChange={(e) => {
                    onSelectChange && onSelectChange({
                        id,
                        checked: e.target.checked
                    })
                }} checked={selected} />
            </Container>}

            <Container className={css.info1}>
                <Container className={css.issuerName}>
                    {carrier.value} {score ? `(score: ${"" + (100 - score)})` : ``}
                </Container>
                <Container className={css.planName}>
                    {plan_name.value}
                </Container>
            </Container>
            {
                pdf && <Container className={css.info3} >
                    <Container className={css.metalLevelText}>{info.value}</Container>
                </Container>
            }
            <Container className={css.info2}>
                <Container>

                </Container>
                <Container className={`${css.planTypePrice} ${css.marginRight24px}`}>
                    <Container className={css.planType}>
                        {premium.label}
                    </Container>
                    <Container className={css.planPrice}>
                        $ {premium.value}
                    </Container>
                </Container>
                <Container className={`${css.planTypePrice}`}>
                    <Container className={css.planType}>
                        {deductible.label}
                    </Container>
                    <Container className={css.planPrice}>
                        $ {deductible.value}
                    </Container>
                </Container>
            </Container>
            {
                !pdf && info && <Container className={css.info3} >
                    <Container className={css.info3}>
                        <Badge style={{ height: "18px" }} color={metalLevelColors[info.value]} />
                        <Container className={css.metalLevelText}>{info.value}</Container>
                    </Container>
                    {exportImage && <Container onPress={() => { exportImage() }} className={css.info4}>
                        <ShareAltOutlined />
                    </Container>}
                </Container>
            }
        </Container>
    </Container>
}
